<template>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>Item Description</th>
          <th>UoM</th>
          <th>Total Qty</th>
          <th>Cost %</th>
          <th>Total Cost</th>
          <th>Per Unit Cost</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="items.length">
          <ProductionComponentItem
              @checkQtyUpdate="checkQtyUpdate"
              v-for="(item, i) in items"
              :key="i"
              :item="item"
              :index="i"
          />
        </template>
        <template v-else>
          <tr>
            <td colspan="4" class="fw-bold text-center">No Data found!</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup>

import ProductionComponentItem from "../production/ProductionComponentItem.vue";

const props = defineProps(['items'])
const emit = defineEmits(['checkQtyUpdate'])
const checkQtyUpdate = (qty) => {
  emit('checkQtyUpdate', qty)
}

</script>
