<template>
  <tr>
    <td class="width">{{ item.product_costingable.name }}</td>
    <td class="width">{{ item.unit_of_measurment }}</td>
    <td class="width">
      <input
          v-model="item.total_quantity"
          type="number"
          class="form-control text-center"
          placeholder="Qty"
          :min="0"
          @blur="checkQty($event)"
      >
    </td>
    <td class="width">{{ item.percentage }} %</td>
    <td class="width">{{ item.amount }}</td>
    <td class="width">{{ item.rate }}</td>
  </tr>
</template>

<script setup>
const props = defineProps(['item', 'index'])
const emit = defineEmits(['checkQtyUpdate'])

const checkQty = (event) => {
  if (props.index == 0) {
    emit('checkQtyUpdate', event.target.value)
  }
}
</script>

<style scoped>
.width {
  width: 300px;
}
</style>
