<template>
  <tr>
    <td class="width">{{ item.name }}</td>
    <td>
      <input
          v-model="totalQty"
          type="number"
          class="form-control text-center"
          :min="0"
      >
    </td>
    <td class="width">{{ item.rate }}</td>
    <td class="width">{{ totalAmount }}</td>
  </tr>
</template>

<script setup>
import {computed, inject} from "vue";

const props = defineProps(['item'])
const formData = inject("formData")

const totalQty = computed(() => {
  const totalQuantity = (props.item.quantity * formData.value.production_quantity).toFixed(2);
  return props.item.total_quantity = totalQuantity
})

const totalAmount = computed(() => {
  return (props.item.total_quantity * props.item.rate).toFixed(2);
})

</script>

<style scoped>
.width {
  width: 300px;
}
</style>
